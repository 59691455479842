<template>
<!--  <page-header-wrapper>-->
  <a-drawer width="85%" :visible="open" @close="onClose">
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
                            <a-col :md="8" :sm="24">
                              <a-form-item label="Marketing Name" prop="name">
                                <a-input v-model="queryParam.name" placeholder="" allow-clear/>
                              </a-form-item>
       </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Product Model" prop="productModel">
                <a-input v-model="queryParam.productModel" placeholder="" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Product Type" prop="productType">
                <a-select
                  placeholder="-Product Type-"
                  style="width: 100%"
                  showSearch
                  v-model="queryParam.productType"
                  optionFilterProp="title"
                >
                  <a-select-option v-for="item in productTypeList" :key="item.id" :value="item.id" :title="item.name">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Available Tank Size" prop="tankSizeId">
<!--                <a-select v-model="queryParam.tankSizeId" placeholder="-All Tank Capacity-">-->
<!--                  <a-select-option :key="item.id" v-for="(item,index) in TankSizeList">{{ item.name }}</a-select-option>-->
<!--                </a-select>-->

                <a-select
                  placeholder="-All Tank Capacity-"
                  style="width: 100%"
                  showSearch
                  v-model="queryParam.tankSizeId"
                  optionFilterProp="title"
                >
                  <a-select-option v-for="item in TankSizeList" :key="item.id" :value="item.id" :title="item.name">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Activation Type" prop="activationType">
                <a-select placeholder="-All Activation Type-" v-model="queryParam.activationType" >
                  <a-select-option :key="0">Button Activated</a-select-option>
                  <a-select-option :key="1">Inhale Activated</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

<!--                          <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="规格id" prop="skuId">-->
<!--                <a-input v-model="queryParam.skuId" placeholder="请输入规格id" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="默认为0 自定义尺寸 否则关联{zb_public_config.id}" prop="tankSizeId">-->
<!--                <a-input v-model="queryParam.tankSizeId" placeholder="请输入默认为0 自定义尺寸 否则关联{zb_public_config.id}" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="尺寸值" prop="tankSizeValue">-->
<!--                <a-input v-model="queryParam.tankSizeValue" placeholder="请输入尺寸值" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="品牌id" prop="brandId">-->
<!--                <a-input v-model="queryParam.brandId" placeholder="请输入品牌id" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <template v-if="advanced">
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="产品型号/产品编号" prop="productModel">-->
<!--                  <a-input v-model="queryParam.productModel" placeholder="请输入产品型号/产品编号" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="产品名/营销名" prop="name">-->
<!--                  <a-input v-model="queryParam.name" placeholder="请输入产品名/营销名" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="产品信息" prop="userId">-->
<!--                  <a-input v-model="queryParam.userId" placeholder="请输入产品信息" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->

<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="其他选项 否则关联{zb_public_config.id}" prop="otherOptionId">-->
<!--                  <a-input v-model="queryParam.otherOptionId" placeholder="请输入其他选项 否则关联{zb_public_config.id}" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="其他选项值" prop="otherOptionValue">-->
<!--                  <a-input v-model="queryParam.otherOptionValue" placeholder="请输入其他选项值" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />Search</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />Clear All</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['cigarette:brandSku:add']">-->
<!--          <a-icon type="plus" />Add-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['cigarette:sku:edit']">-->
<!--          <a-icon type="edit" />Modify-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['cigarette:sku:remove']">-->
<!--          <a-icon type="delete" />Delete-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['cigarette:sku:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />

      <a-row :gutter="[16,16]">
        <a-col :span="4" v-hasPermi="['cigarette:brandSku:add']">
          <div class="goodsDateAdd" @click="$refs.createForm.handleAdd(brandId)">
            <a-icon type="plus" class="goodsDateAdd-icon" style="font-size: 50px;" />
            <div class="goodsDateAdd-title">Create SKU</div>
          </div>
        </a-col>
        <a-col :span="4" v-for="(item,index) in list">
          <div class="goodsDateInfo" :style="[{
            background:(item.status === 0 || item.status === 2) ? '#f8f8f8':'#fff'
          }]">
            <div class="goodsDateInfo-absolute" >
              <template v-if="item.status !== 2">
                <a-icon type="play-circle" theme="twoTone" @click.stop="changeStatus(item,1)" v-if="item.status === 0" style="font-size: 28px;" />
                <a-icon type="pause-circle" theme="twoTone" @click.stop="changeStatus(item,0)" v-else style="font-size: 28px;" />
              </template>

              <!--              <a-icon type="eye" theme="twoTone" style="font-size: 28px;margin-left: 5px" />-->
              <a-icon type="edit" v-hasPermi="['cigarette:brandSku:edit']" @click="$refs.createForm.handleUpdate(item, undefined)" theme="twoTone" style="font-size: 28px;margin-left: 5px" />
            </div>
            <div class="goodsDateInfo-GoodsDate">
              <div class="goodsDateInfo-GoodsDate-flex">
                <img class="goodsDateInfo-GoodsDate-flex-img" :src="item.logo" />
                <div class="goodsDateInfo-GoodsDate-flex-name">{{ item.name }}</div>
              </div>
            </div>
            <div class="goodsDateInfo-bottom" v-if="item.status === 1">
              <div class="goodsDateInfo-bottom-flex">
                <a-button type="primary" @click="gotoFlavor(item)" v-hasPermi="['cigarette:brandSku:flavor']" v-if="item.productType === 0">Flavor</a-button>
                <a-button type="primary" @click="gotoCurves(item)" v-hasPermi="['cigarette:brandSku:curves']">Curves</a-button>
<!--                <a-button type="primary">Batch</a-button>-->
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <!-- 数据展示
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
<!--      <a-table-->
<!--        :loading="loading"-->
<!--        :size="tableSize"-->
<!--        rowKey="id"-->
<!--        :columns="columns"-->
<!--        :data-source="list"-->
<!--        :pagination="false"-->
<!--        :bordered="tableBordered"-->
<!--      >-->
<!--        <span slot="createTime" slot-scope="text, record">-->
<!--          {{ parseTime(record.createTime) }}-->
<!--        </span>-->
<!--        <span slot="operation" slot-scope="text, record">-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['cigarette:brandSku:edit']">-->
<!--            <a-icon type="edit" />Modify-->
<!--          </a>-->

<!--          <a-divider type="vertical" v-hasPermi="['cigarette:brandSku:flavor']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['cigarette:brandSku:flavor']">-->
<!--            <a-icon type="eye" />Flavor-->
<!--          </a>-->
<!--           <a-divider type="vertical" v-hasPermi="['cigarette:brandSku:curves']" />-->
<!--           <a @click="gotoCurves" v-hasPermi="['cigarette:brandSku:curves']">-->
<!--            <a-icon type="eye" />Curves-->
<!--          </a>-->

<!--          <a-divider type="vertical" v-hasPermi="['cigarette:brandSku:batch']" />-->
<!--           <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['cigarette:brandSku:batch']">-->
<!--            <a-icon type="eye" />Batch-->
<!--          </a>-->
<!--        </span>-->
<!--      </a-table>-->
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `${total} results`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-drawer>

<!--  </page-header-wrapper>-->
</template>

<script>
import { pageSku,listSku, delSku,updateSku } from '@/api/cigarette/sku'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import Template from "@/views/sms/template";

export default {
  name: 'Sku',
  components: {
    Template,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      open:false,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        skuId: null,
        brandId: null,
        productModel: null,
        name: null,
        status: null,
        content: null,
        userId: null,
        type: null,
        tankSizeId: null,
        tankSizeValue: null,
        otherOptionId: null,
        otherOptionValue: null,
        pageNum: 1,
        pageSize: 10
      },
      brandId:"",
      columns: [
        // {
        //   title: '${comment}',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '规格id',
        //   dataIndex: 'skuId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '品牌id',
        //   dataIndex: 'brandId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Product Model',
          dataIndex: 'productModel',
          align: 'center'
        },
        {
          title: 'Marketing Name',
          dataIndex: 'name',
          align: 'center'
        },
        // {
        //   title: 'Status',
        //   dataIndex: 'status',
        //   align: 'center'
        // },
        {
          title: 'Product Introduction',
          dataIndex: 'content',
          align: 'center'
        },
        // {
        //   title: '产品信息',
        //   dataIndex: 'userId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Compatibility',
          dataIndex: 'type',
          align: 'center',
          customRender(t,r) {
            if(r.type == 1) {
              return 'OMNI Hub'
            } else {
              return 'OMNI NFC'
            }
          }
        },
        // {
        //   title: 'Status',
        //   dataIndex: 'status',
        //   align: 'center',
        //   customRender(t,r) {
        //     if(r.type == 1) {
        //       return 'Enable'
        //     } else if(r.status == 2) {
        //       return 'Draft'
        //     } else {
        //       return 'Disable'
        //     }
        //   }
        // },
        // {
        //   title: '默认为0 自定义尺寸 否则关联{zb_public_config.id}',
        //   dataIndex: 'tankSizeId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Tank Size',
          dataIndex: 'tankSizeValue',
          align: 'center'
        },
        // {
        //   title: '其他选项 否则关联{zb_public_config.id}',
        //   dataIndex: 'otherOptionId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Other Option',
          dataIndex: 'otherOptionValue',
          align: 'center'
        },
        {
          title: 'Status',
          dataIndex: 'status',
          align: 'center',
          customRender(t,r) {
            if(r.type == 1) {
              return 'Enable'
            } else if(r.status == 2) {
              return 'Draft'
            } else {
              return 'Disable'
            }
          }
        },
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      productTypeList: [],
      TankSizeList: []
    }
  },
  filters: {
  },
  created () {
    console.log('this.$route.query')
    console.log(this.$route.query)
    // this.getList()
  },
  computed: {
  },
  // watch: {
  //   '$route': {
  //     immediate: true,
  //     handler(to, from) {
  //       console.log('this.$route.query')
  //       console.log(to.query)
  //       // this.queryParam.skuId = to.query.skuId || '';
  //       this.getList()
  //     }
  //   }
  // },
  methods: {
    changeStatus(item,status) {

      let that = this;
      this.$confirm({
        title:status == 1 ?  'Are sure to enble this SKU?' : 'Are sure to Disable this SKU?',
        content: "",
        icon:"exclamation-circle",
        okText: "Yes",
        cancelText: "No",
        onOk () {
          let newItemData = JSON.parse(JSON.stringify(item));
          newItemData.status = status;
          return updateSku(newItemData).then(res => {
            if(res.success) {
              that.$set(item,'status',status);
            }
          })
          // return updateBrand(updateBrandData)
          //   .then(() => {
          //     that.getList()
          //   })
        },
        onCancel () {
        }
      })

    },
    gotoCurves(record) {
      if(record.status !== 1) {

      } else {
        this.$router.push({
          path:'/brandSku/myCurves',
          query:
            {
              skuId: record.id
            }
        })
      }

    },

    gotoFlavor(record) {
      if(record.status !== 1) {

      } else {
        this.$router.push({
          path:'/brandSku/myFlavor',
          query:
            {
              skuId: record.id
            }
        })
      }
        // this.$router.push({
        //   path:'/brandSku/myFlavor',
        //   query:
        //     {
        //       skuId: record.id
        //     }
        // })
      // this.$router.push({
      //   path:'/brandSku/myFlavor',
      //   query:
      //     {
      //       skuId: record.id
      //     }
      // })
    },
    onClose() {
      this.open = false;
    },
    show(record) {
      this.brandId=record.id;
      this.queryParam.brandId = this.brandId;
      this.open = true;
      this.getList();
    },
    /** 查询新-产品规格信息列表 */
    getList () {
      this.loading = true
     pageSku(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        skuId: undefined,
        brandId: this.brandId,
        productModel: undefined,
        name: undefined,
        status: undefined,
        content: undefined,
        userId: undefined,
        type: undefined,
        tankSizeId: undefined,
        tankSizeValue: undefined,
        otherOptionId: undefined,
        otherOptionValue: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delSku(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('cigarette/brand-sku/export', {
            ...that.queryParam
          }, `新-产品规格信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.goodsDateAdd {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 8px;
  flex-direction: column;
}
.goodsDateAdd:hover {
  border: 1px solid blue;

  .goodsDateAdd-icon {
    color:blue;
  }

  .goodsDateAdd-title {
    color: blue;
  }
}
.goodsDateInfo {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 8px;
  flex-direction: column;
}

.goodsDateInfo-GoodsDate-flex-img {
  height: 200px;
  width: 80%;
}

.goodsDateInfo-GoodsDate-flex-name {
  width: 80%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.goodsDateInfo-absolute {
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;
  z-index: 2;
}
.goodsDateInfo-GoodsDate {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.goodsDateInfo-GoodsDate-flex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.goodsDateInfo-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  display: none;
}

.goodsDateInfo-bottom-flex {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}
.goodsDateInfo:hover {
  border: 1px solid blue;
  .goodsDateInfo-absolute {
    display: block;
  }

  .goodsDateInfo-bottom {
    display: block;
  }
}
</style>

